import * as React from "react"
import Spacer from "../components/spacer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from '../components/link';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Spacer />
    <div className='p40 text-center'>
      <h1 className='m0'>404: Not Found</h1>
      <p className='m0'>Page not found. Please return to <Link className='link ul-link' to='/'>Homepage</Link></p>
    </div>
    <Spacer />
  </Layout>
)

export default NotFoundPage
